// Contact24.tsx

import { BiEnvelope, BiMap, BiPhone } from "react-icons/bi";
import React from "react";

type LinkProps = {
  label: string;
  url: string;
};

type ContactProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  link: LinkProps;
};

type Props = {
  tagline: string;
  heading: string;
  description: string;
  contacts: ContactProps[];
};

// Default values for Contact24 component
export const Contact24Defaults: Props = {
  tagline: "Get in Touch",
  heading: "Contact Us",
  description: "Reach out for more information or assistance.",
  contacts: [
    {
      icon: <BiEnvelope className="size-12" />,
      title: "Email",
      description: "Reach us at our Email 24/7",
      link: {
        label: "info@my-e-health.com",
        url: "mailto:info@my-e-health.com",
      },
    },
    {
      icon: <BiPhone className="size-12" />,
      title: "Phone (UK)",
      description: "Reach us at our UK office number during business hours.",
      link: {
        label: "+44 (0)1223 92 6812",
        url: "tel:+44 20 8638 6201",
      },
    },
    {
      icon: <BiPhone className="size-12" />,
      title: "Phone (Sweden)",
      description: "Reach us at our office number during business hours.",
      link: {
        label: "+46 (0) 70 406 5696",
        url: "tel:+46704065696",
      },
    },
    {
      icon: <BiMap className="size-12" />,
      title: "Office (UK)",
      description:
        "My-E-Health UK Limited\nLowin House, Tregolls Road\nTruro, Cornwall TR1 2NA\nCompany No.: 14217826",
      link: {
        label: "",
        url: "#",
      },
    },
    {
      icon: <BiMap className="size-12" />,
      title: "Office (Sweden)",
      description: "My-E-Health\nRödklintsgatan 2B\nS-218 73 Tygelsjö, Sweden",
      link: {
        label: "",
        url: "#",
      },
    },
  ],
};

export const Contact24 = (props: Partial<Props>) => {
  const { tagline, heading, description, contacts } = {
    ...Contact24Defaults,
    ...props,
  };

  return (
    <section className="relative px-[5%] py-16 md:py-24 lg:py-28 bg-fixed bg-center bg-cover">
      <div className="absolute inset-0 bg-white opacity-80"></div>
      <div className="relative container text-center">
        <div className="mb-12 max-w-lg md:mb-18 lg:mb-20 mx-auto">
          <p className="mb-3 font-semibold md:mb-4">{tagline}</p>
          <h2 className="mb-5 text-5xl font-bold md:mb-6 md:text-7xl lg:text-8xl">
            {heading}
          </h2>
          <p className="md:text-md">{description}</p>
        </div>
        <div className="flex flex-wrap justify-center">
          {contacts.map((contact, index) => (
            <div
              key={index}
              className={`flex flex-col items-center text-center w-full md:w-1/2 lg:w-1/3 mb-12 ${
                index === 3 || index === 4 ? "lg:w-1/2" : "lg:w-1/3"
              }`}
            >
              <div className="mb-5 md:mb-6 flex justify-center">
                {contact.icon}
              </div>
              <h3 className="mb-3 text-2xl font-bold md:mb-4 md:text-3xl md:leading-[1.3] lg:text-4xl">
                {contact.title}
              </h3>
              <p className="mb-5 md:mb-6 whitespace-pre-line">
                {contact.description}
              </p>
              {contact.link.url && (
                <a
                  className="underline ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-border-primary focus-visible:ring-offset-2"
                  href={contact.link.url}
                >
                  {contact.link.label}
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
